<template >
    <div class="logList">
        <div class="logButton">
            <div class="search" style="display:flex">
                <span >
                    <el-select class="inputProject" v-model="center_id" placeholder="请选择中心">
                        <el-option value=""></el-option>
                        <el-option v-for="itemPro in centerList" :key="'selectPro_' + itemPro.id" :label="itemPro.name" :value="itemPro.id"></el-option>
                    </el-select>
                </span>
                <!-- 搜索 -->
                <div class="searchContent" style="margin-left:15px;">
                    <el-input placeholder="请输入虚拟实验名称" v-model="searchContent" class="input-with-select" clearable @clear="getData"></el-input>
                </div> 
                <el-button type="primary" @click="getData">搜索</el-button>
            </div>
        </div>
        <!-- 列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                        prop="name"
                        label="项目名称"
                        width="240">
                    </el-table-column>
                    <!-- 头像 -->
                    <el-table-column
                        label="级别"
                        width="100"
                        >
                        <template slot-scope="scope">
                            <span v-if="scope.row.level == 0">慕乐</span>
                            <span v-if="scope.row.level == 1">ilabx</span>
                            <span v-if="scope.row.level == 2">省一流</span>
                            <span v-if="scope.row.level == 3">国一流</span>
                        </template>
                    </el-table-column>
                    <!--姓名  -->
                    <el-table-column
                        prop="census_browse"
                        label="浏览记录总数"
                        width="120">
                    </el-table-column>
                    <!--手机号 -->
                    <el-table-column
                        prop="census_total"
                        label="通过记录总数"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="developerName"
                        label="负责人"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="ilanx_url"
                        label="申报链接">
                        <template slot-scope="scope">
                            <a :href="scope.row.ilanx_url" target="__blank">{{scope.row.ilanx_url}}</a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="url"
                        label="申报页面">
                        <template slot-scope="scope">
                            <a :href="scope.row.url" target="__blank">{{scope.row.url}}</a>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @current-change="getData" 
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="listTotal">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
    import logList from "@/assets/js/schoolAdmin/exp/index.js";
    export default {
        ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/schoolAdmin/exp/index.scss";
</style>